<template>
  <v-container>
    <v-row>
      <h1 class="headline">
        Документ
      </h1>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <!-- <input v-model.number="page" type="number" style="width: 5em" /> /{{
        numPages
      }}
      <button @click="rotate += 90">&#x27F3;</button>
      <button @click="rotate -= 90">&#x27F2;</button>
      <button @click="$refs.pdf.print()">print</button>
      <div style="width: 100%">
        <div
          v-if="loadedRatio > 0 && loadedRatio < 1"
          style="background-color: green; color: white; text-align: center"
          :style="{ width: loadedRatio * 100 + '%' }"
        >
          {{ Math.floor(loadedRatio * 100) }}%
        </div> -->
        <pdf
          v-for="i in numPages"
          ref="pdf"
          :src="src"
          :page="i"
          :rotate="rotate"
          :key="i"
          @password="password"
          @progress="loadedRatio = $event"
          @error="error"
          @num-pages="onNumPages($event)"
          @link-clicked="page = $event"
        ></pdf>
        <!-- </div> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { stringify } from "query-string";
import config from "@/config";
import pdf from "vue-pdf";

const API_URL_FILES = process.env.VUE_APP_BASE_API_URL + "/files";

export default {
  name: "documentview",
  components: {
    pdf
  },
  data: () => ({
    files: {},
    filesNames: [],
    src: undefined,
    loadedRatio: 0,
    page: 1,
    numPages: 1,
    rotate: 0,
    task: null,
    show: true
  }),
  computed: {
    CONFIG() {
      // eslint-disable-next-line no-console
      console.log("CONFIG: ", config.data());
      return config.data();
    }
  },
  methods: {
    onUploaded(filelist) {
      // eslint-disable-next-line no-console
      console.log("onUploaded!", filelist);
      // add new files on a page
      // filelist.map(resp_json => {
      //   ...
      // });
      // just reload files
      this.reloadFiles();
    },
    download(url) {
      var win = window.open(url);
      // win.focus();
      // var win = window.open(url, "_blank");
      // win.focus();
    },
    // просто загружает список всех файлов
    reloadFiles() {
      console.log("Loading file list...");
      fetch(`${API_URL_FILES}?${stringify(this.$route.params)}`)
        .then(resp => resp.json())
        .then(resp_json => {
          console.log("Response:", resp_json);
          this.files = resp_json;
          // get filenames with extension from the response
          this.filesNames = Object.getOwnPropertyNames(resp_json).filter(x =>
            x.match(/[^\\]*\.(\w+)$/)
          );
          console.log("Files:", this.files);
          console.log("Filenames:", this.filesNames);
        });
    },
    password: function(updatePassword, reason) {
      updatePassword(prompt('password is "test"'));
    },
    error: function(err) {
      console.log(err);
    },
    onNumPages(value) {
      if (!isNaN(value)) {
        console.log("NumPages", value);
        console.log("NumPages t", typeof value);
        this.numPages = value;
      }
    }
  },
  mounted() {
    // this.$route.params.id
    this.src = config.data().api.baseUrl + "/download/" + this.$route.params.id;
    // this.task = pdf
    //   .createLoadingTask(
    //     config.data().api.baseUrl + "/download/" + this.$route.params.id
    //   )
    //   .promise.then(pdf => {
    //     console.log("numPages:", pdf.numPages);
    //     console.log("pdf", pdf);
    //     this.numPages = pdf.numPages;
    //     // this.src =
    //     //   config.data().api.baseUrl + "/download/" + this.$route.params.id;
    //     // console.log("src", this.src);
    //   });
    // //this.reloadFiles();
  }
};
</script>
